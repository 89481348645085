import { Form, InputNumber, Popconfirm, Table, Typography ,Input,message} from 'antd';
import React, {useEffect, useState, useGlobal} from "reactn";
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';
import {useParams} from 'react-router-dom'




// const originData = [];
// for (let i = 0; i < 5; i++) {
//   originData.push({
//     key: i.toString(),
//     name: `Edward ${i}`,
//     age: 32,
//     address: `%${i}`,
//   });
// }
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const TargetDetail = (props) => {
  let params = useParams()
  let id = params.id;

  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.key === editingKey;
  
  const {Search} = Input;
  let [totalCount, setTotalCount] = useState(0)
  let [selectedRows, setSelectedRows] = useState([])
  let [search, setSearch] = useState(false)
  let [loading, setLoading] = useState(false)
  let [modules] = useGlobal("modules");
  let [isSmall] = useGlobal('isSmall');
  let [user,] = useGlobal("user");

  let [languagesCms] = useGlobal("languagesCms"); 

  let path = props.location.pathname.split('/')[1];
  let module
  if (modules !== null) {
      [module] = modules.filter(el => el._id === path);
  }

  let handleTableChange = async (page, filters, sorter) => {
      get(page, filters, sorter);
  }

  let get = async (page, filters, sorter) => {
      if (page) {
          page = {page: page.current, pageSize: page.pageSize}
      } else {
          page = {page: 1, pageSize: 10};
      }
      let shortString = 'order';

      if (sorter) {
          if (Object.keys(sorter).length) {
              shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
          }
      }

      let _params = {sort: shortString, ...page,id:id};

      if (search.length > 2) {
          _params["search"] = search.toLowerCase();
          _params["searchFields"] = "title";
      }
      let restData = await api.get('/api/targetDsrList/'+id).then(({data}) => {
          if (data.result) {
              setLoading(false)
              setTotalCount(data.result.total);
              return data.result.map((item, key) => {
                item.key = key;
                return item;
            })
          }
      });
      setData(restData);
  }

  useEffect(() => {
      if (modules) {
          setLoading(true)
          get();
      }
  }, [])
  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      target: '',
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const save = async (key) => {
    console.log("KEYYYYYYYYY    ",key)
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        console.log("NewData     ",newData[index])
        setData(newData);
        setEditingKey('');
        if(newData[index] && newData[index].target_id){
          let createdData={
            target:newData[index].target,
            percentage:newData[index].percentage,
            realizedTarget:newData[index].realizedTarget,

          }
            api.put("/rest/targets/" + newData[index].target_id, createdData).then(({ data: { result, result_message } }) => {
              if (result_message.type === 'success') {
                message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              }
              else
                message.error(languagesCms.ERROR_SAVE_RECORD, 2);
            })
          
        }
        else{
          let createdData={
            target:newData[index].target,
            percentage:newData[index].percentage,
            attendeeId: newData[index].attendee_id,
            distributorSAPCode: id,
            realizedTarget:newData[index].realizedTarget,
            percentage:newData[index].percentage,
            realized:newData[index].realized,
            type:'dsr',
            targetParentId:id
          }
          api.post("/rest/targets", createdData).then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            } else {
              message.error(result_message.message, 2);
            }
          })        }
      } else {
        console.log("burasıııı else")
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
  const columns = [
    {
     title: "Satıcı",
        dataIndex: 'salesPerson',
        width: '15%',
    },
    {
      title: 'Gerçekleşen',
      dataIndex: 'realizedTarget',
      width: '15%',
      editable: true,
    },
    {
      title: 'Yüzde',
      dataIndex: 'percentage',
      width: '40%',
      editable: true,
    },
    {
      title: 'Hedef',
      dataIndex: 'target',
      width: '40%',
      editable: true,
    },
    {
      title: 'İşlemler',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Kaydet
            </Typography.Link>
            <Popconfirm title="İptal etmek istediğinizden emin misiniz?" onConfirm={cancel}>
              <a>Iptal</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Düzenle
          </Typography.Link>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  );
};
export default TargetDetail;
